import React from 'react';
import flagsmith from 'flagsmith/isomorphic';
import { FlagsmithProvider } from 'flagsmith/react';

import { Eloqua } from './src/components/third-party/eloqua';
import { GoogleTagManager } from './src/components/third-party/google-tagmanager';

// eslint-disable-next-line react/display-name, react/prop-types -- gatsby component
export default ({ element }) => {
	return (
		<FlagsmithProvider flagsmith={flagsmith}>
			{element}

			{process.env.NODE_ENV === 'production' && (
				<>
					<Eloqua />
					<GoogleTagManager />
				</>
			)}
		</FlagsmithProvider>
	);
};
